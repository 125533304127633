@mixin body2 {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.43;
}

@mixin body1 {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
}

@mixin h1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.15;
}

@mixin h2 {
  font-size: 3.75rem;
  font-weight: 300;
  line-height: 1.2;
}

@mixin h3 {
  font-size: 3rem;
  font-weight: 400;
  line-height: 1.16;
}

@mixin h4 {
  font-size: 2.125rem;
  font-weight: 400;
  line-height: 1.06;
}

@mixin h5 {
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 1;
}

@mixin h6 {
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.2rem;
}

@mixin subtitle1 {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
}

@mixin subtitle2 {
  font-size: 0.8125rem;
  font-weight: 500;
  line-height: 1.5rem;
}

@mixin paper {
  color: rgba(0, 0, 0, 0.87);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-color: #fff;
}

@mixin snap {
  scroll-snap-type: x mandatory;
  -webkit-scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
}

/**
 * css-logical-props rule
 * A mixin that applies the `inset` shorthand property with fallback for browsers that don't support it.
 *
 * @param {Any} $top - The value for the `top` position or `inset`.
 * @param {Any} $right - The value for the `right` position or `inset`.
 * @param {Any} $bottom - The value for the `bottom` position or `inset`.
 * @param {Any} $left - The value for the `left` position or `inset`.
 */
@mixin inset-poly($top, $right, $bottom, $left) {
  @supports (inset: $top) {
    inset: $top $right $bottom $left;
  }

  @supports not (inset: $top) {
    inset: $top $right $bottom $left;
  }
}
